import originConfig from '../config/origin.config';
import {
    host as configHost
} from '../config/lib.config';

const { port, hostname } = location;

export function getOriginKey() {
    const { dev = [], staging = [] } = configHost;
    const originKey = port ? 'local'
        : dev.includes(hostname) ? 'dev'
        : staging.includes(hostname) ? 'staging'
        : 'prod';

    return originKey;
}

export function getOrigin() {
    const originKey = getOriginKey();
    const origin = originConfig[originKey];

    return origin;
}