import { detect } from 'detect-browser';
import { load, loadFallback, getVersionFromUrl } from '../../lib/util/ck-editor.util';

const { parent: parentWindow } = window;
const { EV_FEATURE = {} } = parentWindow;
const { COMPOSITION_LEGACY_TEXT_EDITOR = true } = EV_FEATURE;
const browser = detect();
const version = getVersionFromUrl();
const upgrade = browser?.name === 'safari' || !COMPOSITION_LEGACY_TEXT_EDITOR;
const EV_CKEDITOR_HELPER = {
    browser,
    version,
    upgrade,
    load,
    loadFallback
};

window.EV_CKEDITOR_HELPER = {
    ...EV_CKEDITOR_HELPER
};
