import { pick } from 'lodash';
import ckEditorConfig from '../config/ckeditor.config.json';

const { host } = location;
const { legacyVersions = [], ckEditor = {}, externalPlugins = {} } = ckEditorConfig;
const versionConfig = getConfig();
const inheritedVersionConfig = getInheritedConfig();

function getConfig() {
    const integration = host.endsWith(ckEditorConfig?.integration?.domain);
    const editorConfig = getEditorConfig();
    const versionConfig = getVersionConfig();
    const versions = getEditConfigVersions();

    for (const version of versions) {
        versionConfig[version].integration = integration;
        versionConfig[version].ckEditor = ckEditor;
        versionConfig[version].externalPlugins = externalPlugins;

        versionConfig[version].editorConfig = {
            ...editorConfig,
            ...ckEditorConfig[version].editorConfig,
            extraPlugins: [
                ...(editorConfig?.extraPlugins || []),
                ...(ckEditorConfig[version]?.editorConfig?.extraPlugins || [])
            ],
            removePlugins: [
                ...(editorConfig?.removePlugins || []),
                ...(ckEditorConfig[version]?.editorConfig?.removePlugins || [])
            ]
        }

        if (legacyVersions.includes(version)) {
            versionConfig[version].editorConfig.extraPlugins = versionConfig[version].editorConfig.extraPlugins.join(',');
            versionConfig[version].editorConfig.removePlugins = versionConfig[version].editorConfig.removePlugins.join(',');
        }
    }

    return versionConfig;
}

function getInheritedConfig() {
    const versions = getEditConfigVersions({ inherit: true });
    const inheritedConfig = versions
        .map(version => getInheritedConfigForVersion({ version }))
        .reduce((aggregatedConfig, currentConfig) => ({...aggregatedConfig, ...currentConfig}), {});

    return inheritedConfig;
}

function getInheritedConfigForVersion({ version }) {
    const { [version]: versionConfig } = ckEditorConfig;
    const { inherit, editorConfig } = versionConfig;
    const { [inherit]: parentConfig } = ckEditorConfig;
    const { editorConfig: parentEditorConfig } = parentConfig;
    const inheriVersionConfig = {
        ...versionConfig,
        ...parentConfig,
        editorConfig: {
            ...parentEditorConfig,
            ...editorConfig
        }
    }

    if (Array.isArray(inheriVersionConfig.editorConfig.toolbar) && Array.isArray(inheriVersionConfig.editorConfig.toolbar_Full)) {
        delete inheriVersionConfig.editorConfig.toolbar_Full;
    }

    return { [version]: inheriVersionConfig };
}

function getEditorConfig() {
    const { editorConfig: editorConfigBase = {} } = ckEditorConfig;
    const {
        format_tags = [],
        font_names = [],
    } = editorConfigBase;
    const editorConfig = {
        ...editorConfigBase,
        format_tags: format_tags.join(';'),
        font_names: font_names.join(';')
    };

    return editorConfig;
}

function getEditConfigVersions({ inherit: configWithInherit = false} = {}) {
    const editorConfigVersions = new Set();

    for (const [version, { editorConfig = {}, inherit }] of Object.entries(ckEditorConfig)) {
        if ((!configWithInherit && !inherit && Object.keys(editorConfig).length) || (configWithInherit && inherit)) {
            editorConfigVersions.add(version);
        }
    }

    return Array.from(editorConfigVersions);
}

function getVersionConfig() {
    const { versions = [] } = ckEditorConfig;
    const versionConfig = pick(
        ckEditorConfig,
        [
            ...versions,
            'defaultVersion',
            'externalPlugins',
            'urlVersion',
            'adapter'
        ]
    );

    return versionConfig;
}

export default { ...versionConfig, ...inheritedVersionConfig };