import { detect } from 'detect-browser';
import originConfig from '../config/origin.config';
import ckEditorConfig from '../config/ckeditor.config.js';
import ckEditorUiConfig from '../../app/config/ckeditor.config.json';
import { getOriginKey } from './origin.util';

const { parent: parentWindow } = window;
const { hostname, pathname } = location;
const browser = detect();

export function load({ version = '' }, handleEditorReady = () => {}, handleEditorLoadError = () => {}) {
    const config = {
        eventName: ckEditorConfig.eventName,
        ...ckEditorConfig[version],
        origin: originConfig
    };

    loadNewEditor({ version, config, handleEditorReady, handleEditorLoadError });
}

export function loadFallback({ url }) {
    const { CKEDITOR } = window;

    let loadFallbackEditor = Promise.resolve();

    if (!CKEDITOR) {
        loadFallbackEditor = loadFallbackEditor.then(() => loadEditor({ url }));
    }

    return loadFallbackEditor;
}

function loadEditor({ url }) {
    const jqueryAdapterUrl = getJqueryAdaptorUrl({ url });

    let loadFallcackScripts = loadScripts({ url });

    if (jqueryAdapterUrl) {
        loadFallcackScripts = loadFallcackScripts.then(() => loadScripts({ url: jqueryAdapterUrl}));
    }

    return loadFallcackScripts;
}

function getJqueryAdaptorUrl({ url }) {
    const { adapter = {} } = ckEditorConfig;
    const { jquery = {} } = adapter;
    const { views = [], find = '', replace = '' } = jquery;
    const jqueryAdapterUrl = views.some(view => pathname.startsWith(view)) && find && replace
        ? url.replace(find, replace) : '';

    return jqueryAdapterUrl
}

function loadScripts({ url }) {
    const { head } = document;
    const script = document.createElement('script');

    script.src = url;

    return new Promise((resolve, reject) => {
        script.addEventListener('load', resolve);
        script.addEventListener('error', reject);

        head.appendChild(script);
    });
}

export function getVersionFromUrl() {
    const currentPath = getCurrentPath();
    const { defaultVersion, urlVersion = {} } = ckEditorConfig;
    const emailBuilderIframe = [
        '4.20.1-email-classic-editor',
        '4.20.1-workflow-email-classic-editor'
    ];

    let versionFromUrl = defaultVersion;

    for (const [url, version] of Object.entries(urlVersion)) {
        if (currentPath.startsWith(url)) {
            versionFromUrl = version;

            break;
        }
    }

    if (versionFromUrl === '4.20.1-landing-page-builder' && window === parentWindow) {
        versionFromUrl = '4.20.1-landing-classic-editor';
    } else if (emailBuilderIframe.includes(versionFromUrl) && window !== parentWindow) {
        versionFromUrl = '4.20.1-email-builder';
    }

    return versionFromUrl;
}

function getCurrentPath() {
    const currentPath = parentWindow.location.pathname;

    return currentPath;
}

function loadNewEditor({ config, handleEditorReady = () => {}, handleEditorLoadError = () => {} }) {
    const origin = getOrigin({ config });
    const relativeUrl = getRelativeUrl({ config });
    const url = [origin, relativeUrl].join('/');

    importStyles({ config });

    importEditor({ config, url, handleEditorReady, handleEditorLoadError });
}

function getStylesheetUrls({ config }) {
    const originKey = getOriginKey();
    const origin = originConfig[originKey] || ' ';
    const { styles: stylesConfig = {} } = config;
    const { css = [], basePath = {} } = stylesConfig;
    const currentBasePath = basePath[originKey];
    const stylesheetUrls = new Set();

    let stylesheetUrl;

    for (const style of css) {
        stylesheetUrl = [
            origin,
            currentBasePath,
            style
        ].filter(entry => entry).join('/');

        stylesheetUrls.add(stylesheetUrl);
    }

    return [...stylesheetUrls];
}

function getStylesheet({ href }) {
    const stylesheet = document.createElement('link');

    stylesheet.rel = 'stylesheet';
    stylesheet.href = href;

    return stylesheet;
}

function getStylesSheets({ config }) {
    const stylesheets = getStylesheetUrls({ config })
        .map(href => getStylesheet({ href }));

    return stylesheets;
}

function importStyles({ config }) {
    const { head } = document;
    const stylesheets = getStylesSheets({ config });

    for (const stylesheet of stylesheets) {
        head.appendChild(stylesheet);
    }
}

function getOrigin({ config }) {
    const origin = hostname === 'localhost' ? config.origin.local : config.origin.dev;

    return origin;
}

function getRelativeUrl({ config }) {
    const relativeUrl = hostname === 'localhost' && config.relativeUrl.local || config.relativeUrl.dev;

    return relativeUrl;
}

function importEditor({ config, url, handleEditorReady = () => {}, handleEditorLoadError = () => {} }) {
    const { CKEDITOR } = window;

    let loadCkEditor = Promise.resolve();

    if (!CKEDITOR) {
        loadCkEditor = loadCkEditor.then(() => loadEditor({ url }));
    }

    loadCkEditor = loadCkEditor.then(() => handleEditorLoaded({ config, handleEditorReady }))
        .catch(handleEditorLoadError);

    return loadCkEditor;
}

function getExternalPlugins({ config = {} }) {
    const originKey = getOriginKey();
    const origin = originConfig[originKey];
    const baseUrl = config?.externalPlugins?.baseUrl?.[originKey];
    const { pluginFileName = {} } = config?.externalPlugins || ckEditorUiConfig?.externalPlugins || {};
    const { default: defaultPluginFileName } = pluginFileName;
    const customPlugins = ckEditorUiConfig?.plugins?.customPlugins?.plugins || [];
    const browserExternalPlugins = getBrowserExternalPlugins({ config });
    const externalPlugins = new Map([...browserExternalPlugins]);

    let externalPluginUrl = '';
    let externalPluginFileName = '';

    for (const plugin of customPlugins) {
        externalPluginFileName = pluginFileName[plugin] || defaultPluginFileName;
        externalPluginUrl = [
            origin,
            baseUrl,
            plugin,
            externalPluginFileName
        ].join('/');

        externalPlugins.set(plugin, externalPluginUrl);
    }

    return externalPlugins;
}

function getBrowserExternalPlugins({ config = {} }) {
    const { integration = false } = config;
    const { name: browserName } = browser;
    const browserExternalPluginConfig = integration
        && config?.externalPlugins?.browser?.[browserName]?.plugins
        || config?.externalPlugins?.browser?.['*']?.plugins
        || {};
    const browserExternalPlugins = new Map();

    for (const [pluginName, url] of Object.entries(browserExternalPluginConfig)) {
        browserExternalPlugins.set(pluginName, url);
    }

    return browserExternalPlugins;
}

function getBrowserEditorConfig({ config }) {
    const { integration = false } = config;
    const { name: browserName } = browser;
    const browserEditorConfig = integration
        && config?.externalPlugins?.browser?.[browserName]?.editorConfig
        || config?.externalPlugins?.browser?.['*']?.editorConfig
        || {};

    return browserEditorConfig;
}

function setExternalPlugins({ config }) {
    const { CKEDITOR } = window;
    const externalPlugins = getExternalPlugins({ config });

    for (const [plugin, url] of externalPlugins) {
        CKEDITOR.plugins.addExternal(plugin, url);
    }
}

function setDtd({ config }) {
    const { CKEDITOR } = window;
    const { ckEditor = {} } = config;
    const { dtd = {} } = ckEditor;
    const { $removeEmpty = {} } = dtd;

    for (const [tag, removeEmpty] of Object.entries($removeEmpty)) {
        CKEDITOR.dtd.$removeEmpty[tag] = removeEmpty;
    }
}

function handleEditorLoaded({ config, handleEditorReady = () => {} }) {
    const { CKEDITOR } = window;
    const { editorConfig } = config;
    const { version } = CKEDITOR;
    const browserEditorConfig = getBrowserEditorConfig({ config });
    const detail = {
        dependency: 'CKEditor',
        version,
        editorConfig: {
            ...editorConfig,
            ...browserEditorConfig
        }
    };

    setDtd({ config });

    setExternalPlugins({ config });

    handleEditorReady(detail);
}